@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "coolvetica";
  src: 
    url("fonts/coolvetica_rg-webfont.woff") format("woff"),
    url("fonts/coolvetica_rg-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "inter";
  src: url("fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "inter-bold";
  src: url("fonts/Inter-Bold.ttf");
}
